.mediaInfo {
  :global {
    .ant-image {
      height: 128px;

      .ant-image-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ant-image-error {
      height: 128px;

      .ant-image-img-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  span {
    margin-left: 5px;
  }
}

.wrapper {
  padding-bottom: 8px;

  & > div {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 380px);
    min-height: 400px;
    padding: 8px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
      text-align: center;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: @scrollBar;
    }
  }
}

.images {
  display: flex;
  display: grid;
  grid-template-columns: repeat(auto-fill, 128px);
  justify-content: center;
  grid-gap: 3px;
}

.title {
  position: relative;
  width: 100%;
  padding: 8px;
  box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.15);
  z-index: 10;

  h2 {
    margin-bottom: 0;
    color: @primary-color;
    font-size: 14px;
    font-weight: normal;
    border: 2px solid @lightGray-color;
    border-radius: 6px;
    text-align: center;
  }
}

.month {
  text-transform: capitalize;
}

.block {
  margin-bottom: 5px;
}

.info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: @primary-color;
}

.noMessagesWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: @primary-color;

  .noMessageTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;