.table {
  :global {
    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }
  }

  .actions {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
  }
}

.searchSettings {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;

  .search {
    flex-basis: 400px;
    opacity: 0;
    cursor: default;
  }
}

.cell {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  white-space: nowrap;
  align-items: center;
  
  span {
    cursor: pointer;
  }
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;