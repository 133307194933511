.taskCount {
  :global {
    .ant-form-item-label > label {
      font-weight: bold;
      color: @primary-color;
    }

    .ant-input {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-btn {
      background-color: #eff1f2;
      color: @primary-color;
      border-radius: 7px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainButton {
  text-align: right;
}

.button {
  display: flex;
  justify-content: flex-end;
}

.cell {
  color: @primary-color;
  margin-bottom: 8px;
  margin-left: 12px;
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: @primary-color;
  margin-bottom: 14px;
}

.icon {
  color: @primary-color;
  margin-left: 8px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;