.editModal {
  :global {
    .ant-form-item-label > label {
      font-weight: bold;
      color: @primary-color;
    }

    .ant-btn {
      border-radius: 7px;
    }

    .ant-input {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: @primary-color;
      border-radius: 7px;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;