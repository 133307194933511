@import '../../../shared/lib/theme/vars.less';

.wrapper {
  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #597393;
  }

  .order {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  div {
    font-size: 24px;
  }

  h2 {
    margin-bottom: 0;
  }
}

.back-button {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: middle;
  color: @gray-color;
  border: none;
  background-color: transparent;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
}

.icon {
  margin-left: 0;
}

.name {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.main-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.info-wrapper {
  display: flex;
}

.info-block {
  p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  b {
    margin-left: 25px;
  }
}

.total {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  border-top: 2px solid @line-color;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;