.productComplaints {
  margin-top: auto;
  text-align: center;

  h2 {
    font-size: 26px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  justify-content: center;
  grid-gap: 15px;
  text-align: left;

  p {
    margin-bottom: 12px;
    font-size: 16px;
    color: @primary-color;
  }
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;