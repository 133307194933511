.table {
  :global {
    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }
  }

  .actions {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
  }
}

.searchSettings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;

  :global {
    .ant-btn {
      color: @primary-color;
      border-radius: 7px;
    }
  }

  .clear {
    svg {
      width: 20px;
      height: 20px;
      color: @primary-color;
    }
  }

  .balances {
    color: @primary-color;
    font-size: 20px;
    font-weight: 700;
  }

  .options {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 16px !important;
    }
  }
}

.cell {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  white-space: nowrap;
  align-items: center;

  span {
    cursor: pointer;
  }
}

.link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.nowrap {
  white-space: nowrap;
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;