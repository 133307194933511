.suppParser {
  :global {
    .ant-btn {
      color: @primary-color;
      background-color: #ededed;
      border-radius: 7px;
    }

    .ant-card {
      max-width: 270px;
      width: 100%;
      border-radius: 7px;
    }

    .ant-card-body {
      height: 100%;
      color: @primary-color;
    }
  }
}

.sources {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.addSource {
  position: absolute;
  top: -60px;
  right: 0;
}

.button {
  margin-bottom: 20px;
}

.wrapper {
  position: relative;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.remove {
  text-align: center;

  .removeTitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: @primary-color;
  }

  .button:not(:first-of-type) {
    margin-left: 15px;
  }
}

.center {
  display: flex;
  justify-content: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;