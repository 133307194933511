.buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.content {
    display: flex;
    width: 100%;
    gap: 24px;
}

.button {
    margin-bottom: 24px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;