@import '~antd/dist/antd.less';

html {
  scroll-behavior: smooth;
}

p {
  margin-bottom: 0;
}

#root {
  height: 100vh;
  overflow-y: auto;
}

.ant-layout {
  min-height: 100%;
  position: relative;
}

.ant-layout-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;