.body {
  :global {
    .ant-card {
      border-radius: 7px;
    }

    .ant-menu-item::after {
      display: none;
    }

    .ant-menu-item:active {
      background: transparent;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
      a {
        color: @primary-color;
      }
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      font-weight: bold;
      background: transparent;
    }

    .ant-menu-sub.ant-menu-inline {
      background-color: #ffffff;
    }

    .ant-menu-title-content {
      color: @primary-color;
    }
  }
}

.title {
  font-size: 36px;
  line-height: 49px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: @primary-color;
}

.menu {
  min-height: 576px;
  border-radius: 7px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.body {
  display: flex;
  justify-content: space-between;

  .menuTitle {
    padding: 5px 10px;
    font-weight: 18px;
    font-weight: bold;
    color: @primary-color;
  }

  .itemMenu {
    width: 17%;
    background-color: #ffffff;
    border-radius: 7px;
    padding: 24px 0 0 24px;
  }

  .itemBody {
    width: 81%;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;