.opener {
  height: 32px;
  border-radius: 4px;
}

.refundList {
  :global {
    .ant-input,
    .ant-input::placeholder {
      color: @primary-color;
    }

    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }

    .ant-tag {
      padding: 4px 16px;
      border: 1px solid #c7d3dc;
      border-radius: 16px;
    }

    .ant-btn {
      border-radius: 7px;
      color: @primary-color;
    }

    .ant-btn-primary {
      color: @white-color;
    }

    .anticon-clear {
      color: @primary-color;
    }
  }
}

.menu {
  :global {
    .ant-dropdown-menu-item {
      color: #597393;
    }

    .ant-dropdown-menu {
      border-radius: 7px;
    }
  }
}

.refundTotal {
  line-height: 28px;
  color: #597393;
  display: block;
  font-weight: 700;
  font-size: 20px;
  margin-top: 22px;
}

.refundSelected {
  line-height: 20px;
  color: #597393;
  display: block;
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
}

.buttons {
  display: flex;
  gap: 12px;
  margin-top: 22px;
}

span.avatar {
  border-radius: 7px;
  min-width: 44px;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.tasksWrapper {
  :global {
    .ant-card {
      margin-bottom: 18px;
    }
  }
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: @primary-color;
}

.refundInfoWrapper {
  padding: 16px 0;
  color: @primary-color;

  p {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 6px;
  }
}

.refundPositionsTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;