.employeesProfile {
  :global {
    .ant-card,
    .ant-btn {
      border-radius: 7px;
    }

    .ant-card-body {
      height: 100%;
    }

    .ant-input {
      font-size: 16px;
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      font-size: 16px;
      color: @primary-color;
      border-radius: 7px;
    }
  }
}

.title {
  font-size: 28px;
  font-weight: bold;
  color: @primary-color;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.center {
  display: flex;
  justify-content: center;
}

.empty {
  font-size: 16px;
  font-weight: bold;
  color: @primary-color;
  text-align: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;