.chatList {
  height: 100%;
  background-color: #fff;
  :global {
    .ant-tabs-nav-wrap {
      width: 100%;
    }

    .ant-tabs-nav-list {
      width: 100%;
      height: 48px;
    }

    .ant-card-body {
      padding: 0;
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      width: 100%;
      color: @primary-color;

      & + .ant-tabs-tab {
        margin: 0;
      }
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;