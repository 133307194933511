.productInfo {
  p {
    margin-bottom: 12px;
    font-size: 16px;
    color: @primary-color;
  }
}

.wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.category {
  display: flex;
  margin-bottom: 12px;
  font-size: 16px;
  color: @primary-color;

  strong {
    margin-right: 5px;
  }
}

.item {
  position: relative;
  margin-right: 20px;

  &:not(:first-of-type)::before {
    position: absolute;
    top: 0;
    right: -17px;
    content: '/';
    width: 10px;
    height: 20px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;