.chatMessages {
  :global {
    .ant-card {
      height: 100%;
    }

    .ant-card-body {
      height: 100%;
      padding: 0;
      background-color: @lightGray-color;
    }

    .infinite-scroll-component {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.panel {
  width: 100%;
  height: 48px;
  background-color: @white-color;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.contentWrapper {
  max-height: calc(100vh - 335px);
  height: 100%;
  min-height: 470px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  padding: 8px 8px 0 8px;
  background-color: @lightGray-color;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
    text-align: center;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: @scrollBar;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  width: 100%;
  min-height: 514px;
  padding: 8px;
  background-color: @lightGray-color;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.message {
  width: fit-content;
  max-width: 80%;
  margin-bottom: 4px;
}

.messageInter {
  margin-left: auto;

  .info {
    background-color: @lightBlue-color;
  }

  .created {
    margin-left: auto;
  }

  &.messageLast {
    .info {
      border-bottom-right-radius: 4px;
    }
  }
}

.messageClient {
  &.messageLast {
    .info {
      border-bottom-left-radius: 4px;
    }
  }
}

.author {
  font-size: 12px;
  color: @primary-color;
}

.info {
  background-color: @white-color;
  padding: 8px 16px;
  border-radius: 20px;
}

.created {
  width: fit-content;
  padding-right: 12px;
  padding-left: 12px;
  font-size: 11px;
  color: @gray-color;
}

.noMessagesWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #597393;
}

.noMessageIcon {
  margin-bottom: 16px;
  svg {
    width: 29px;
    height: 29px;
    fill: #597393;
  }
}

.noMessageTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 12px;
}

.noMessageText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;