.inputTag {
  :global {
    .ant-tag {
      padding: 1px 7px;
      font-size: 14px;
      color: @primary-color;
    }

    .ant-space-item > .ant-input {
      min-width: 95px;
      margin-right: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }
}

.plus {
  background: @white-color;
  cursor: pointer;

  svg {
    fill: #000000;
  }
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;