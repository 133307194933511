.order {
  &.pt {
    padding-top: 1rem;
  }
  :global {
    .ant-row {
      margin-left: -8px;
      margin-right: -8px;
      margin-bottom: 24px;
    }

    .ant-col {
      > div {
        height: 100%;
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    .ant-card {
      border-radius: 7px;
    }

    .ant-btn {
      border-radius: 7px;
      color: @primary-color;
    }

    .ant-tabs-tab {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      line-height: 33px;
      color: @gray-color;
      transition: all 0.2s ease-in-out;

      & + .ant-tabs-tab {
        margin: 0;
      }
    }

    .ant-tabs-nav::before {
      display: none;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-nav-wrap {
      justify-content: center;
    }

    .ant-tabs-nav {
      height: 28px;
      padding: 2px;
      background-color: @lightGray-color;
      border-radius: 6px;
    }

    .ant-tabs-tab-btn {
      width: 100%;
      text-align: center;
    }

    .ant-tabs-tab-active {
      background-color: @white-color;
      border-radius: 4px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .buttons {
    display: flex;
    gap: 1rem;

    .primary {
      color: @white-color;
    }
  }

  .title {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}

.return {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: middle;
  color: @gray-color;

  .arrow {
    margin-left: 0;
  }

  span {
    margin-left: 10px;
  }
}

.title {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: bold;
  color: @primary-color;
}

.tasksWrapper {
  :global {
    .ant-card {
      margin-bottom: 18px;
    }
  }
}

.info {
  display: flex;
}

.infoTasks {
  display: flex;

  :global {
    .ant-col {
      > div {
        height: auto;
      }
    }
  }
}

.ordersInfo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 18px;

  :global {
    .ant-card {
      margin-bottom: 0;
    }
  }
}

.taskCard {
  min-width: 350px;
  width: 100%;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  color: @primary-color;

  h2 {
    font-weight: bold;
    color: @primary-color;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.empty {
  font-size: 16px;
  font-weight: bold;
  color: @primary-color;
  text-align: center;
}

.count {
  font-size: 20px;
  margin-bottom: 0;
}

.copy_buttons_wrapper {
  display: flex;
  align-items: center;
}

.copy_button_all_ids{
  margin-left: 8px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;