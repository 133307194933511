.useColumn {
  padding: 8px;

  :global {
    .ant-input {
      margin-bottom: 8px;
      display: block;
      color: @primary-color;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-explain-error {
      max-width: 180px;
      margin-bottom: 5px;
    }
  }
}

.useColumnDate {
  max-width: 200px;
  padding: 8px;

  :global {
    .ant-input-group {
      margin-bottom: 8px;
    }

    .ant-input {
      display: block;
      color: @primary-color;
    }

    .ant-picker-input > input {
      color: @primary-color;
    }

    .ant-picker {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.useColumnAutoselect {
  padding: 8px;

  :global {
    .ant-select {
      width: 200px;
      color: @primary-color;
    }
  }
}

.useColumnMultiSelect {
  display: flex;
  flex-direction: column;
  padding: 8px;

  :global {
    .ant-select {
      width: 200px;
      margin-bottom: 8px;
      color: @primary-color;
    }
  }
}

.useColumn,
.useColumnDate,
.useColumnMultiSelect {
  :global {
    .ant-space {
      width: 100%;
      justify-content: space-between;
    }

    .ant-space-item,
    .ant-btn {
      width: 100%;
    }
  }
}

.useColumnTreeSelect {
  display: flex;
  padding: 8px;

  :global {
    .ant-select {
      width: 100%;
      min-width: 200px;
      color: @primary-color;
    }
  }
}

.item {
  display: flex;
  margin-bottom: 8px;

  p {
    display: flex;
    align-items: center;
    min-width: 41px;
    padding: 0 11px;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-right: 0;
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.selectType {
  margin-bottom: 8px;
}

.useColumnDate {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;