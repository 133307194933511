.chatMessages {
  :global {
    .ant-card {
      height: 100%;
    }

    .ant-card-body {
      height: 100%;
      padding: 0;
    }
  }
}

.panel {
  width: 100%;
  height: 48px;
  color: @white-color;
}

.contentWrapper {
  height: 514px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
    text-align: center;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: @scrollBar;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  background-color: @lightGray-color;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  justify-content: flex-end;
}

.message {
  width: fit-content;
  max-width: 80%;
  min-width: 100px;
  min-height: 50px;
  margin-bottom: 4px;

  :global {
    .ant-image {
      width: 80%;
      max-height: 275px;
      height: 100%;

      .ant-image-img {
        max-width: inherit;
        max-height: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
  }
}

.messageImage {
  max-width: 275px;
  width: 100%;
}

.messageInter {
  margin-left: auto;

  .info {
    background-color: @lightBlue-color;
  }

  .created {
    margin-left: auto;
  }

  &.messageLast {
    .info {
      border-bottom-right-radius: 4px;
    }
  }
}

.messageClient {
  .info {
    border-bottom-left-radius: 4px;
  }
}

.author,
.authorImage {
  font-size: 12px;
  color: @primary-color;
}

.authorImage {
  padding: 8px 16px;
}

.info,
.infoImage {
  background-color: @white-color;
  padding: 8px 16px;
  border-radius: 20px;
}

.infoImage {
  padding: 2px;
}

.created {
  width: fit-content;
  padding-right: 12px;
  padding-left: 12px;
  font-size: 11px;
  color: @gray-color;
}

.date {
  font-size: 12px;
  line-height: 16px;
  color: #94a2ab;
  text-align: center;
}

.text {
  min-height: 22px;
}

.imageWrapper {
  position: relative;
  max-width: 275px;
  width: 100%;
  height: 275px;
  border-radius: 20px;
  background-size: cover;
  text-align: center;

  .imageBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    backdrop-filter: blur(4px);
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;