.remove {
  padding-top: 15px;
  text-align: center;

  .removeTitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: @primary-color;
    white-space: pre-line;
    &.left {
      text-align: left !important;
    }
    &.right {
      text-align: right !important;
    }
  }

  .buttons {
    &.left {
      text-align: left !important;
    }
    &.right {
      text-align: right !important;
    }
    .button:not(:first-of-type) {
      margin-left: 15px;
    }
  }

  :global {
    .ant-btn {
      border-radius: 7px;
    }
  }

  .content {
    margin-bottom: 20px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;