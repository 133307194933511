.task {
  :global {
    .ant-card {
      border-radius: 7px;
    }

    .ant-btn {
      color: @primary-color;
      border-radius: 7px;
    }
  }
}

.buttons {
  display: flex;
  width: 100%;

  button:first-child {
    margin-right: 24px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.content {
  display: flex;
  width: 100%;
  gap: 24px;
}

.center {
  display: flex;
  justify-content: center;
}

.button {
  color: @primary-color;
  border-radius: 6px;
}

.title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  color: @primary-color;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;