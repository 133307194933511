.input {
  width: 100%;
  padding: 4px 11px;
  color: #597393;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
  transition: 0.2s ease-in-out;

  &:hover {
    border-color: #7c8ea1;
  }

  &:focus {
    border: 1px solid #7c8ea1;
    box-shadow: 0 0 0 2px rgb(89 115 147 / 20%);
    outline: none;
  }
}

.inputError {
  border-color: @error-color;

  &:hover {
    border-color: @error-color;
  }

  &:focus {
    border-color: @error-color;
    border-right-width: 1px;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    outline: 0;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;