.card {
  width: 100%;

  :global {
    .ant-btn {
      color: #ededed;
    }
  }
}

.form {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.title {
  font-size: 28px;
  font-weight: bold;
  color: @primary-color;
}

.name {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  color: @primary-color;
}

.item {
  display: flex;
  align-items: center;
  width: 250px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
  color: @primary-color;

  .data {
    width: 100%;
    margin-left: 8px;
    font-weight: normal;
  }
}

.formItem {
  :global {
    .ant-form-item-required {
      font-size: 16px;
      color: @primary-color;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;