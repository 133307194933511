.measurements {
  padding-top: 20px;

  :global {
    .ant-btn {
      border-radius: 7px;
    }

    .ant-form-item {
      margin-bottom: 8px;
    }

    .ant-form-item-label > label {
      font-weight: 600;
      color: @primary-color;
    }

    .ant-col-24.ant-form-item-label {
      padding-bottom: 2px;
    }

    .ant-input {
      border-radius: 7px;
      color: @primary-color;
    }
  }
}

.head {
  margin-bottom: 22px;
}

.title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: @primary-color;
}

.group {
  margin-bottom: 14px;

  .groupItem {
    margin-bottom: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: @primary-color;

    span:first-child {
      font-weight: 600;
    }
  }
}

.groupTitle {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.wrapper {
  display: flex;
  gap: 12px;
}

.buttons {
  display: flex;

  .button {
    margin-right: 15px;
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.deliveryList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.parcelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;