.wrapper div {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #597393;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 14px;
  color: @gray-color;
}

.name {
  font-size: 20px;
  font-weight: bold;
}

.phone,
.pavilion {
  font-size: 14px;
}

.infoBlock {
  p {
    font-size: 14px;
    display: flex;
    margin-bottom: 8px;
  }

  span {
    margin-left: 8px;
    word-break: break-word;
  }
}

.comment {
  display: flex;
  align-items: center;
  gap: 5px;

  strong {
    font-size: 20px;
  }

  p {
    width: 100%;
    padding: 10px 12px;
    margin-bottom: 0;
    background-color: #f3f5f8;
    border-radius: 4px;
  }
}

.source {
  display: flex;

  strong {
    font-size: 14px;
  }

  p {
    display: flex;
    flex-wrap: wrap;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;