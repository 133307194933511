.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  .header {
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    padding: 1rem;
    justify-content: end;
    width: 100%;

    svg {
      color: white;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .container {
    img {
      object-fit: contain;
    }
  }
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;