@import '../../shared/lib/theme/vars.less';

header.Header {
  padding: 0 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 72px;
  z-index: 9;
  align-items: center;
  background-color: white;

  :global {
    .ant-menu-submenu-title {
      height: 72px;
    }

    .ant-menu-horizontal {
      line-height: 72px;

      & > .ant-menu-submenu:hover {
        color: @primary-color;
        background-color: @hover-color;
      }
    }

    .ant-menu {
      border-bottom: none;
      height: 72px;
    }

    .ant-menu-horizontal > .ant-menu-submenu {
      top: 0;
      margin-top: 0;
    }

    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
      border-bottom: none;
    }

    .ant-menu-horizontal > .ant-menu-item-active,
    .ant-menu-horizontal > .ant-menu-item-open,
    .ant-menu-horizontal > .ant-menu-item-selected,
    .ant-menu-horizontal > .ant-menu-item:hover,
    .ant-menu-horizontal > .ant-menu-submenu-active,
    .ant-menu-horizontal > .ant-menu-submenu-open,
    .ant-menu-horizontal > .ant-menu-submenu-selected,
    .ant-menu-horizontal > .ant-menu-submenu:hover,
    .ant-menu-horizontal > .ant-menu-item::after,
    .ant-menu-horizontal > .ant-menu-submenu::after {
      border-bottom: none;
    }
  }
}
.HeaderLogo {
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 400;
  color: @primary-color;
}
.HeaderRightContainer {
  display: flex;
  align-items: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;