.addBuilding {
  :global {
    .ant-select {
      margin-bottom: 20px;
      color: @primary-color;
    }

    .ant-input {
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 7px;
      color: @primary-color;
    }

    .ant-btn {
      margin-left: auto;
      width: 140px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-btn {
      border-radius: 7px;
    }
  }
}

.title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: @primary-color;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .labelName {
    margin-bottom: 5px;
    font-weight: bold;
    color: @primary-color;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;