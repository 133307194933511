.back-button {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: middle;
  color: @gray-color;
  border: none;
  background-color: transparent;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
}

.icon {
  margin-left: 0;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;