.exceptions {
  :global {
    .ant-card {
      border-radius: 7px;
    }

    .ant-input,
    .ant-input::placeholder {
      color: @primary-color;
    }

    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }
  }
}

.menu {
  :global {
    .ant-dropdown-menu-item {
      color: #597393;
    }

    .ant-dropdown-menu {
      border-radius: 7px;
    }
  }
}

.button {
  :global {
    .ant-btn {
      border-radius: 7px;
    }
  }
}

.title {
  font-weight: bold;
  font-size: 24px;
  color: @primary-color;
}

.search {
  max-width: 420px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.table {
  tr {
    color: @primary-color;
  }
}

.empty {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: @primary-color;
}

.center {
  display: flex;
  justify-content: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;