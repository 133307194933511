.AddProductOptions {
  padding: 24px 16px;
  border-radius: 8px;
  background-color: white;

  > div {
    margin-bottom: 5px;
  }

  h3 {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #597393;
  }
}

.AddProductOptionsRemove {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.AddProductOptionsBtn {
  display: flex;
  margin-top: 36px;
  margin-left: -8px;
  margin-right: -8px;
  justify-content: flex-end;

  :global {
    .ant-form-item {
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 0;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;