.logs {
  text-align: right;

  :global {
    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }

    .ant-tag {
      padding: 4px 16px;
      border: 1px solid #c7d3dc;
      border-radius: 16px;
    }

    .anticon-clear {
      color: @primary-color;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
}

.clear {
  margin-left: 16px;
  margin-bottom: 12px;

  svg {
    width: 20px;
    height: 20px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;