.pavilions {
  :global {
    .ant-card {
      border-radius: 7px;
    }
  }
}

.buttonAdd {
  :global {
    .ant-btn {
      border-radius: 7px;
    }
  }
}

.menu {
  :global {
    .ant-dropdown-menu-item {
      color: #597393;
    }

    .ant-dropdown-menu {
      border-radius: 7px;
    }
  }
}

.spinner {
  :global {
    display: flex;
    justify-content: center;
  }
}

.wrapper {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin-bottom: 0;
  }
}

.table {
  tr {
    color: @primary-color;
  }
}

.remove {
  text-align: center;

  .removeTitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: @primary-color;
  }

  .button:not(:first-of-type) {
    margin-left: 15px;
  }

  :global {
    .ant-btn {
      border-radius: 7px;
    }
  }
}

.line {
  cursor: pointer;
}

.empty {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: @primary-color;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;