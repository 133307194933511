.wrapper {
  .menu {
    :global {
      .ant-menu-title-content > a {
        color: @primary-color;
      }

      .ant-menu-item::after {
        left: 0;
        right: 0;
      }
    }
  }
}

.headBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 4px;
  background-color: @white-color;

  :global {
    .ant-btn {
      border-radius: 7px;
      color: @primary-color;
    }

    .ant-card-body {
      
    }

    .ant-btn-primary {
      color: @white-color;
    }

    .ant-btn-dangerous {
      color: @error-color;
    }
  }
}

.cards-wrapper {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  :global {
    .ant-card {
      border-radius: 7px;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
}

.content {
  position: relative;
  display: flex;
  justify-content: space-between;

  &::after {
    position: absolute;
    bottom: 20px;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #dde3e9;
  }
}

ul.menu {
  position: relative;
  margin-bottom: 20px;
  background-color: transparent;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #dde3e9;
  }

  li {
    z-index: 1;
  }

  :global {
    .ant-menu-item::after {
      left: 0;
      right: 0;
    }
  }
}

.buttons {
  margin-left: auto;

  :global {
    .ant-btn {
      margin-left: 10px;
    }
  }
}

.blockTitle {
  max-width: 50%;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: @primary-color;
  overflow-wrap: break-word;

  &Success {
    color: #008000;
  }

  &Error {
    color: @error-color;
  }
}

.archiveTitle {
  display: flex;
  align-items: center;
  max-width: 50%;

  .blockTitle {
    max-width: unset;
    margin-right: 24px;
    color: #e50000;
  }

  .resposible {
    font-weight: 600;
    font-size: 16px;
    color: @primary-color;
    overflow-wrap: break-word;
  }
}

.link {
  text-decoration: underline;
}

.pointId {
  margin-left: 24px;
}

.data {
  color: @primary-color;
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;