.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  color: @primary-color;

  p {
    margin-bottom: 8px;
  }

  .infoData {
    margin-bottom: 0;
  }

  :global {
    .ant-collapse {
      width: 100%;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      display: flex;
      justify-content: flex-end;
      padding: 0;
      margin-bottom: 16px;
      margin-left: auto;
      font-size: 16px;
      font-weight: bold;
      color: @primary-color;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      color: @primary-color;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
      flex: unset;
    }

    .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      top: 55%;
      right: 0;
    }

    .ant-collapse-header-text {
      padding-right: 20px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  gap: 16px;

  p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
  }
}

.title {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  color: @primary-color;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mainInfoWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wrapper {
  display: flex;
}

.avatar {
  min-width: 100px;
  border-radius: 7px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 15px;

  p {
    font-size: 14px;
  }

  span {
    margin-left: 8px;
  }
}

.infoData {
  display: flex;
  justify-content: space-between;

  span {
    padding-right: 25px;
  }
}

.name {
  font-size: 16px;
}

.detailedInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.data {
  display: flex;
  justify-content: space-between;
}

.totalPrice {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  font-size: 16px;

  span {
    font-weight: bold;
  }

  &::before {
    position: absolute;
    top: -23px;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #ededed;
  }
}

.quantity {
  margin-left: 5px;
  font-weight: bold;
  font-size: 16px;
}

.status {
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    margin-left: 5px;
  }
}

.source {
  margin-left: 62px;

  a {
    word-break: break-word;
    text-decoration: underline;
  }

  .intermediary {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      margin-left: 5px;
    }
    svg {
      cursor: pointer;
    }
  }
}

.removeIntermediary,
.changeStatus {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  color: @primary-color;
  
  .flex {
    display: flex;
    gap: 10px;
  }
}

.changeStatus {
  .footer {
    display: flex;
    gap: 10px;

    .item:nth-child(2) {
      flex-grow: 1;
    }

    .item div:nth-child(2) {
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.buttons {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: end;
}

.priceBeforeRefund {
  margin-left: 5px;
  text-decoration: line-through;
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;