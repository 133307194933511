.control {
  display: flex;
  margin-left: 16px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: #597393;

  span,
  svg {
    width: 20px;
    height: 20px;
  }
}

.menu {
  :global {
    .-title-content {
      width: 100%;
    }

    .ant-dropdown-menu-item {
      color: #597393;
    }

    .ant-dropdown-menu {
      border-radius: 7px;
    }
  }
}

.control-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    margin-right: 10px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;