.menu {
  :global {
    .ant-menu-title-content > span {
      color: @primary-color;
    }

    .ant-menu-item::after {
      left: 0;
      right: 0;
    }

    .ant-menu-item:hover {
      border-bottom: 2px solid @primary-color;
    }

    .ant-menu-item-selected {
      border-bottom: 2px solid @primary-color;
    }
  }
}

:global {
  .ant-empty-description {
    color: @primary-color;
  }
}

ul.menu {
  position: relative;
  background-color: transparent;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #dde3e9;
  }

  li {
    z-index: 1;
  }

  :global {
    .ant-menu-item::after {
      left: 0;
      right: 0;
    }
  }
}

.commission {
  min-height: 260px;

  :global {
    .ant-card {
      border-radius: 7px;
    }

    .ant-card-body {
      height: 100%;
    }

    .ant-input {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-form-item-label {
      label {
        font-weight: 600;
        color: @primary-color;
      }
    }

    .ant-form-item {
      margin-bottom: 8px;
    }

    .ant-btn {
      border-radius: 7px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: @primary-color;
      border-radius: 7px;
    }
  }
}

.form {
  width: 50%;
  margin-top: 24px;

  .row {
    align-items: center;
    padding-left: 16px;
    
    :global {
      .ant-col {
        padding: 1px !important;
        text-align: center;

        .ant-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
}

.center {
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
}

.subtitle {
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: @primary-color;
  margin-top: 24px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;