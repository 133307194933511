.productImages {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  justify-content: center;
  grid-gap: 15px;

  :global {
    .ant-image {
      height: 150px;

      .ant-image-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ant-image-error {
      height: 150px;

      .ant-image-img-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  span {
    margin-left: 5px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;