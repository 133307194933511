.profiles {
  :global {
    .ant-card {
      border-radius: 7px;
    }
  }
}

.title {
  font-size: 36px;
  line-height: 49px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: @primary-color;
}

ul.menu {
  position: relative;
  margin-bottom: 20px;
  background-color: transparent;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #dde3e9;
  }

  li {
    z-index: 1;
  }

  :global {
    .ant-menu-item::after {
      left: 0;
      right: 0;
    }

    .ant-menu-title-content > a {
      color: @primary-color;
    }
  }
}

.body {
  padding: 20px;
  border-radius: 8px;
  background-color: #eff1f2;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;