.remove {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .removeTitle {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: bold;
    color: @error-color;
  }

  p {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 600;
  }

  .link {
    text-decoration: underline;
  }

  :global {
    .ant-btn {
      border-radius: 7px;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;