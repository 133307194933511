.wrapper {
  display: flex;
  justify-content: center;
  flex-grow: 1;

  :global {
    .ant-card {
      max-height: 405px;
      border: none;
      width: 100%;
    }

    .ant-input {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-btn {
      background-color: #eff1f2;
      color: @primary-color;
      border-radius: 7px;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: @primary-color;
}

.title {
  font-weight: 600;
  font-size: 20px;
  color: @primary-color;
}

.icon {
  margin-bottom: 22px;

  svg {
    min-height: 55px;
    min-width: 55px;
    color: @primary-color;
  }
}

.subtitle {
  color: gray;
  font-weight: 500;
  font-size: 20px;
  margin: 16px 0;
}

.scan {
  background: #f3f5f8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 30px 48px 24px;
  align-items: center;
}

.input {
  width: 100%;

  :global {
    .ant-row {
      margin-right: 24px;

      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: @primary-color;
      }
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;