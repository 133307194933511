.addSynonym {
  :global {
    .ant-btn {
      border-radius: 7px;
    }

    .ant-form-item-label > label {
      color: @primary-color;
    }

    .ant-input {
      border-radius: 7px;
      color: @primary-color;
    }
  }
}

.title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: @primary-color;
}

.buttons {
  display: flex;

  .button {
    margin-right: 15px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;