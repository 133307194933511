.legalCreation {
  :global {
    .ant-form-item {
      width: 100%;
    }

    .anticon-question-circle {
      margin-top: 10px;
      margin-left: 10px;

      svg {
        fill: @gray-color;
      }
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .blockTitle {
    margin-bottom: 0;
  }
  :global {
    .anticon-question-circle {
      margin-top: 5px;
    }
  }
}

.block {
  background-color: white;
  border-radius: 4px;
  padding: 24px;
}

.headBlock {
  background: #fafafa;

  border-radius: 4px;
  padding: 24px;
  display: flex;
  justify-content: space-between;

  :global {
    .ant-btn {
      border-radius: 7px;
    }
  }
}

.blockTitle {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: @primary-color;
}

.input {
  width: 100%;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  transition: 0.2s ease-in-out;

  &:hover {
    border-color: #7c8ea1;
  }

  &:focus-visible {
    border: 1px solid #7c8ea1;
    box-shadow: 0 0 0 2px rgb(89 115 147 / 20%);
    outline: none;
  }
}

.row {
  display: flex;
  width: 100%;
  gap: 24px;
  margin-top: 24px;

  :global {
    .ant-form-item {
      margin-bottom: 8px;
    }

    .ant-form-item-label > label {
      color: @primary-color;
      font-weight: 600;
    }

    .ant-card {
      border-radius: 4px;
    }

    .ant-input {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-picker {
      width: 100%;
      border-radius: 7px;

      input {
        color: @primary-color;
      }
    }
  }
}

.card {
  position: relative;
  width: 100%;

  :global {
    .ant-tabs {
      margin-top: -12px;
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      flex-wrap: wrap;
      margin-left: 67px;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 4px;
    }

    .ant-tabs-nav::before {
      display: none;
    }

    .ant-tabs-tab {
      padding-top: 11px;
      font-size: 20px;
      font-weight: bold;
      color: @gray-color;
    }

    .ant-tabs-tab-active {
      color: @primary-color;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: @primary-color;
      border-radius: 7px;
    }
  }
}

.address {
  display: flex;
  justify-content: space-between;
}

.addressTitle {
  position: absolute;
  top: 10px;
  left: 0;
  font-weight: 700;
  font-size: 20px;
  color: @primary-color;
}

.addressError {
  position: absolute;
  top: 45px;
  left: 25px;
  color: @error-color;
}

.icon {
  position: absolute;
  top: 10px;
  right: 0;
}

.iconMinus {
  position: absolute;
  top: 10px;
  right: 30px;
}

.icon svg,
.iconMinus svg {
  color: @primary-color;
  font-size: 20px;
}

.input {
  width: 100%;
  padding: 4px 11px;
  color: #597393;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
  transition: 0.2s ease-in-out;

  &:hover {
    border-color: #7c8ea1;
  }

  &:focus-visible {
    border: 1px solid #7c8ea1;
    box-shadow: 0 0 0 2px rgb(89 115 147 / 20%);
    outline: none;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.item {
  display: flex;
  margin-bottom: 8px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.tooltip {
  position: fixed;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;