.ProfileStatus {
  p {
    margin-bottom: 16px;
  }
}
.ProfileStatusTitle {
  font-size: 18px;
  font-weight: 700;  
  color: #597393;
}
.ProfileStatusData {
  display: flex;
  margin-left: -4px;
  margin-right: -4px;

  > div {
    margin-left: 4px;
    margin-right: 4px;
  }
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;