.address {
  position: relative;

  :global {
    .ant-btn {
      color: @primary-color;
      background-color: #ededed;

      border-radius: 7px;
    }

    .ant-input,
    .ant-input::placeholder {
      color: @primary-color;
    }

    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }
  }
}

.addAddress {
  position: absolute;
  top: -60px;
  right: 0;
}

.menu {
  :global {
    .ant-dropdown-menu-item {
      color: #597393;
    }

    .ant-dropdown-menu {
      border-radius: 7px;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;