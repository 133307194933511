.updateUser {
  :global {
    .ant-form-item-label > label {
      font-weight: bold;
      color: @primary-color;
    }

    .ant-btn {
      border-radius: 7px;
    }

    .ant-btn-text {
      padding: 0;
      margin-bottom: 20px;
      color: @primary-color;

      &:hover,
      &:active,
      &:focus {
        opacity: 75%;
        color: @primary-color;
        background-color: transparent;
      }
    }

    .ant-input,
    .ant-input-password {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: @primary-color;
      border-radius: 7px;
    }
  }
}

.input {
  width: 100%;
  padding: 4px 11px;
  color: #597393;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
  transition: 0.2s ease-in-out;

  &:hover {
    border-color: #7c8ea1;
  }

  &:focus-visible {
    border: 1px solid #7c8ea1;
    box-shadow: 0 0 0 2px rgb(89 115 147 / 20%);
    outline: none;
  }
}

.buttons {
  display: flex;

  .button {
    margin-right: 15px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;