.taskInfo {
  color: @primary-color;

  :global {
    .ant-btn {
      width: 100%;
      margin-bottom: 25px;
      color: @primary-color;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 13px;
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 8px;
}

.mainInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.infoWrapper {
  display: flex;
  width: 100%;
}

.infoBlock {
  p {
    display: flex;
    margin-bottom: 4px;
  }

  strong {
    margin-right: 8px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;