.AnalyticsTopMediatorTable {
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: white;

  :global {
    .ant-pagination {
      display: none;
    }
  }
}

.AnalyticsTopMediatorTableHeader {
  display: flex;
  margin-bottom: 24px;
  align-items: flex-start;
  justify-content: space-between;

  h3 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    line-height: 1.2em;
    font-weight: 600;
    color: @primary-color;
  }
}

.AnalyticsTopMediatorTableFooter {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;