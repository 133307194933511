.card {
  width: 100%;
  flex-grow: 1;

  :global {
    .ant-card-body {
      height: 100%;
    }

    .ant-tag {
      padding: 4px 16px;
      border: 1px solid #c7d3dc;
      border-radius: 16px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      min-height: 38px;
      padding-top: 2px;
    }
  }
}

.wrapper {
  display: flex;
  height: 100%;
}

.avatar {
  min-width: 150px;
}

.status {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.2em;
  color: @gray-color;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 24px;
  color: @primary-color;
}

.name {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.2em;
  color: #597393;
}

.data {
  margin-bottom: 12px;
}

.select {
  width: 200px;
}

.buttons {
  display: flex;

  .button {
    padding: 5px 15px;
    background-color: #ededed;
    border-radius: 7px;
    margin-right: 10px;
    color: @primary-color;
  }

  .ban-button {
    padding: 5px 15px;
    border-radius: 7px;
    margin-right: 10px;
  }
}

.option {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 4px 16px;
  font-size: 12px;
  color: white;
  border: 1px solid #c7d3dc;
  border-radius: 16px;
}

.active {
  color: rgb(16, 142, 233);
  border-color: rgb(16, 142, 233);
}

.ban {
  color: #e50000;
  border-color: #e50000;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;