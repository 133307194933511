.chatMemberInfo {
  width: 100%;
  color: @primary-color;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 8px;
  }

  :global {
    .ant-tag {
      padding: 4px 16px;
      border: 1px solid #c7d3dc;
      border-radius: 16px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      min-height: 38px;
      padding-top: 2px;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  div {
    margin-left: 8px;
  }

  .name {
    font-weight: bold;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .data {
    margin-bottom: 4px;
  }
}

.select {
  width: 200px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  a,
  div {
    flex-grow: 1;

    .button {
      width: 100%;
    }
  }

  .button {
    flex-grow: 1;
    border-radius: 4px;
    color: @primary-color;
  }
}

.option {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 4px 16px;
  font-size: 12px;
  color: white;
  border: 1px solid #c7d3dc;
  border-radius: 16px;
}

.active,
.approve {
  color: rgb(16, 142, 233);
  border-color: rgb(16, 142, 233);
}

.ban,
.reject {
  color: #e50000;
  border-color: #e50000;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;