.wrapper {
  display: flex;
  flex-direction: column;

  :global {
    .ant-input {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-form-item-required {
      font-weight: bold;
      color: @primary-color;
    }

    .ant-btn {
      border-radius: 7px;
    }
  }
}

.form {
  width: 60%;
}

.center {
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  justify-content: flex-end;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;