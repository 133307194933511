.addProduct {
  text-align: center;
}

.title {
  font-size: 36px;
  line-height: 49px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: @primary-color;
}
.body {
  margin-left: -8px;
  margin-right: -8px;
}

.column {
  margin-left: 8px;
  margin-right: 8px;
}

.faq {
  margin-bottom: 24px;
}

.center {
  display: flex;
  justify-content: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;