.ordersPrice {
  display: flex;
  justify-content: space-between;
  height: 100%;

  p {
    margin-bottom: 4px;
    color: @primary-color;
  }
}

.title {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 700;
  color: @primary-color;
  display: block;
}

.prices {
  width: 100%;

  p {
    display: flex;
    justify-content: space-between;
  }
}

.title-prices {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.beginning-price {
  margin-right: 5px;
  margin-left: 5px;
  text-decoration: line-through;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;