.AddProductInfo {
  padding: 24px 16px;
  border-radius: 8px;
  background-color: white;

  :global {
    .ant-btn {
      border-radius: 7px;
    }

    .ant-image-img {
      width: auto;
      height: 250px;
    }

    .ant-image-error {
      width: 250px;
      height: 250px;

      .ant-image-img-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .ant-input,
    .ant-select-selector {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: @primary-color;
      border-radius: 7px;
    }
  }

  > div {
    margin-bottom: 5px;
  }

  h3 {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #597393;
  }

  :global {
    .ant-row {
      margin-left: -8px;
      margin-right: -8px;
    }

    .ant-col {
      > div {
        margin-left: 8px;
        margin-right: 8px;
      }
      label {
        color: @primary-color;
        margin-left: 8px;
      }

      .ant-input {
        color: @primary-color;
        border-radius: 7px;
      }

      .ant-input::placeholder {
        color: @primary-color;
      }
    }
  }
}

.select {
  width: 100%;
}

.button {
  display: flex;
  margin-top: 36px;
  justify-content: flex-end;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-left: 15px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;