.AnalyticsTodayStatistics {
  padding: 24px;
  border-radius: 8px;
  background-color: white;
}
.AnalyticsTodayStatisticsToday {
  font-size: 28px;
  line-height: 1.2em;
  font-weight: 700;
  color: @primary-color;
}
.AnalyticsTodayStatisticsDate {
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 700;
  color: @primary-color;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;