.wrapper {
  display: flex;
  flex-direction: column;
  color: @primary-color;

  :global {
    .ant-form-item-label {
      margin-left: 8px;
    }
    .ant-form-item-label>label {
      color: @primary-color;
      border-radius: 7px;
    }

    .ant-form-item {
      margin-bottom: 12px;
    }

    .ant-form-item-label > label::after {
      content: '';
    }
  }
}

.wrapper label {
  color: @primary-color;
  font-weight: 600;
}

.center {
  display: flex;
  justify-content: center;
}

.info {
  margin-bottom: 24px;
  color: @primary-color;
  line-height: 19px;
  max-width: 600px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;