.wrapper {
  .menu {
    :global {
      .ant-menu-title-content > a {
        color: @primary-color;
      }

      .ant-menu-item::after {
        left: 0;
        right: 0;
      }
    }
  }
}

.cards-wrapper {
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 16px;

  :global {
    .ant-card {
      border-radius: 7px;
    }
  }
}

.arrow {
  margin-left: 0;
}

.content {
  position: relative;
  display: flex;
  justify-content: space-between;

  &::after {
    position: absolute;
    bottom: 20px;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #dde3e9;
  }
}

ul.menu {
  position: relative;
  margin-bottom: 20px;
  background-color: transparent;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #dde3e9;
  }

  li {
    z-index: 1;
  }

  :global {
    .ant-menu-item::after {
      left: 0;
      right: 0;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;