.suppParserList {
  :global {
    .ant-card {
      border-radius: 7px;
    }

    .ant-btn {
      color: @primary-color;
      background-color: #ededed;
      border-radius: 7px;
    }
  }
}

.menu {
  :global {
    .ant-dropdown-menu-item {
      color: #597393;
    }

    .ant-dropdown-menu {
      border-radius: 7px;
    }
  }
}

.card {
  width: 265px;
  margin-bottom: 20px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: bold;
  color: @primary-color;
}

.info {
  color: @primary-color;
  margin-bottom: 15px;
}

.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.empty {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;