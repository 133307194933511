@import '../../shared/lib/theme/vars.less';

ul.headerMenu {
  padding-left: 50px;
  padding-right: 50px;
  background-color: white;

  :global {
    .ant-menu-item::after {
      left: 0;
      right: 0;
    }
    .ant-badge {
      margin-left: 5px;
    }
    .ant-badge-count {
      background-color: @primary-color;
    }

    .ant-menu-title-content > a {
      color: @primary-color;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;