.chatItem {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
}

.chatItemImage {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  display: flex;

  svg {
    width: 32px;
    height: 32px;
  }

  span:first-child {
    align-self: flex-end;
    z-index: 2;
  }
}

.avatarPlaceholder {
  background-color: @chatLightGray-color;
  width: 22px;
  height: 22px;
  padding: 2px;
  border-radius: 50%;

  svg {
    width: 18px;
    height: 18px;
    fill: @chatGray-color;
  }
}

.topPlaceholder {
  margin-left: -8px;
}

.chatItemWrapper {
  display: flex;
  align-items: center;
}

.chatItemInfo {
  display: flex;
  flex-direction: column;

  p {
    font-weight: bold;
    color: @primary-color;
    text-align: left;
  }

  .chatTitle {
    max-width: 250px;
    width: 12vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.chatItemTime {
  color: @gray-color;
}

.chatItemPreview {
  display: flex;

  p {
    margin-right: 6px;
    color: @chatGray-color;
    font-weight: normal;
  }

  .chatItemText {
    margin-left: 8px;
  }

  svg {
    fill: @chatGray-color;
  }
}

.activeChat {
  background-color: @lightGray-color;
}

@media screen and (max-width: 1300px) {
  .chatItemInfo {
    .chatTitle {
      width: 11vw;
    }
  }
}

@media screen and (max-width: 1215px) {
  .chatItemInfo {
    .chatTitle {
      width: 10vw;
    }
  }
}

@media screen and (max-width: 1105px) {
  .chatItemInfo {
    .chatTitle {
      width: 9vw;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;