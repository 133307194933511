.center {
  display: flex;
  justify-content: center;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #597393;
  margin-bottom: 6px;

  :global {
    .ant-tag {
      padding: 4px 16px;
      border: 1px solid #c7d3dc;
      border-radius: 16px;
    }
  }
}

.error {
  border: 1px solid red;
  background-color: lightpink;
  border-radius: 4px;
  padding: 8px;
  max-height: 300px;
  overflow: auto;
}

.json {
  background-color: #333;
  color: white;
  padding: 8px;
  border-radius: 6px;
  overflow: auto;
  max-height: 500px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;