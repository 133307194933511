.postInfo {
  width: 100%;

  a {
    text-decoration: underline;
  }

  :global {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      display: flex;
      justify-content: flex-end;
      padding: 0;
      margin-bottom: 16px;
      margin-left: auto;
      font-size: 16px;
      font-weight: bold;
      color: @primary-color;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      color: @primary-color;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
      flex: unset;
    }
  }
}

.info {
  padding: 8px;
  font-size: 13px;
  background-color: #eff1f2;
  border-radius: 4px;

  div {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
}

.text {
  margin-bottom: 12px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;