.moderChatList {
  height: 100%;
  :global {
    .anticon-control {
      margin-right: 8px;
      margin-left: 16px;

      svg {
        fill: @primary-color;
      }
    }

    .ant-input {
      color: @primary-color;
    }
  }
}

.settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 8px;
}

.control-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    margin-right: 10px;
  }
}

.listWrapper {
  max-height: calc(100vh - 400px);
  height: 100%;
  min-height: 416px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
    text-align: center;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: @scrollBar;
  }
}

.noMessagesWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: @primary-color;
}

.noMessageIcon {
  margin-bottom: 16px;
  svg {
    width: 29px;
    height: 29px;
    fill: @primary-color;
  }
}

.noMessageTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 12px;
}

.noMessageText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;