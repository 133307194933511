.card {
  min-width: 300px;

  :global {
    .ant-card-body {
      height: 100%;
    }

    .ant-btn {
      border-radius: 7px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  p {
    margin-bottom: 12px;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #597393;
}

.block {
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;

    color: #597393;
  }
}

.row {
  font-size: 14px;
  color: @primary-color;

  &:first-child {
    font-weight: 600;
  }
}

.buttons {
  display: flex;
}

.button {
  margin-right: 10px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;