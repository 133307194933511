.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    img {
      object-fit: contain;
    }

    strong {
      font-size: 40px;
    }
  }
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;