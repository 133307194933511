.sizes {
  :global {
    .ant-card {
      border-radius: 7px;
    }

    .ant-btn {
      border-radius: 7px;
    }

    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }
  }
}

.menu {
  :global {
    .ant-dropdown-menu-item {
      color: #597393;
    }

    .ant-dropdown-menu {
      border-radius: 7px;
    }
  }
}

.title {
  margin-bottom: 0;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.table {
  tr {
    color: @primary-color;
  }
}

.remove {
  padding-top: 15px;
  text-align: center;

  .removeTitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: @primary-color;
  }

  .button:not(:first-of-type) {
    margin-left: 15px;
  }

  :global {
    .ant-btn {
      border-radius: 7px;
    }
  }
}

.buttonAdd {
  :global {
    .ant-btn {
      border-radius: 7px;
    }
  }
}

.empty {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: @primary-color;
}

.center {
  display: flex;
  justify-content: center;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;