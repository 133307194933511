.delivery {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  p {
    margin-bottom: 4px;
    color: @primary-color;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  color: @primary-color;
}

.button {
  svg {
    fill: @primary-color;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;