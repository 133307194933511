.chatInfo {
  :global {
    .ant-tabs-top > .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-content {
      position: static;
    }
  }
}

.detailedInfo {
  padding: 8px;
  padding-right: 0;

  .wrapper {
    max-height: calc(100vh - 335px);
    height: 100%;
    min-height: 470px;
    padding-right: 8px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
      text-align: center;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: @scrollBar;
    }
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;