.content {
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;

  .card {
    :global {
      color: @primary-color !important;

      .ant-form {
        color: @primary-color !important;
      }

      .ant-form-item {
        margin: 0;
      }

      .ant-card-body {
        height: 100%;
      }

      .ant-tag {
        padding: 4px 16px;
        border: 1px solid #c7d3dc;
        border-radius: 16px;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        min-height: 38px;
        padding-top: 2px;
      }
    }

    &:first-child {
      flex-grow: 1;
    }

  /*  &:last-child {
      flex-basis: 20rem;
      flex-shrink: 0;
    }*/
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    height: 100%;
  }

  .avatar {
    min-width: 150px;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-left: 24px;
  }

  .status {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 1.2em;
    color: @gray-color;
  }

  .data {
    margin-bottom: 12px;
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .balanceWrapper {
    display: flex;
    align-items: center;
    & > strong{
      margin-right: 0.25rem;
      display: block;
    }
    & > span {
      display: block;
    }
    & > button {
      display: block;
    }
    & > button:first-child{
      margin-right: 0.25rem;
    }
  }

  .select {
    width: 200px;
  }

  .block {
    display: flex;
    gap: 24px;
  }

  .buttons {
    display: flex;

    .button {
      padding: 5px 15px;
      background-color: #ededed;
      border-radius: 7px;
      margin-right: 10px;
    }
  }

  .option {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 4px 16px;
    font-size: 12px;
    color: rgb(0, 0, 0);
    border: 1px solid;
    border-color: rgb(0, 0, 0);
    border-radius: 16px;
  }

  .active {
    color: rgb(16, 142, 233);
    border-color: rgb(16, 142, 233);
  }

  .banned,
  .not_confirmed {
    color: rgb(230, 0, 0);
    border-color: rgb(230, 0, 0);
  }

  .legal {
    span {
      margin-right: 50px;
    }
  }
}

.name {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.2em;
  color: #597393;
  padding-right: 1rem;

  svg {
    width: 24px;
    height: 24px;
  }
}

.verticalWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;