.NameProfilesCard {
  display: flex;
  align-items: center;
}

.NameProfilesCardName {
  margin-left: 10px;
  font-size: 16px;
  line-height: 24px;
  color: @primary-color;
  white-space: nowrap;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;