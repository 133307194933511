.productParser {
  :global {
    .ant-collapse-content-box {
      padding: 0;
    }

    .ant-tabs-tab {
      color: @primary-color;
    }

    .ant-btn {
      width: fit-content;
      border-radius: 7px;
    }

    .ant-tag {
      margin: 0;
    }

    .ant-tabs-tab-active {
      font-weight: 600;
    }

    tbody > tr > td {
      color: @primary-color;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      align-items: center;
      padding: 8px;
      color: @primary-color;
      cursor: default;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      padding-top: 8px;
    }

    .ant-alert-error {
      max-width: 250px;
    }

    .ant-alert-message {
      color: @primary-color;
    }
  }

  p {
    font-size: 14px;
    color: @primary-color;
  }

  max-height: 90vh;
  margin-top: 15px;
  overflow-y: auto;
  scrollbar-color: rgba(89, 115, 147, 0.2);

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(89, 115, 147, 0.1);
    box-shadow: inset 0 0 6px rgba(89, 115, 147, 0.1);
    background-color: #f5f5f5;
    border: 1px solid rgba(89, 115, 147, 0.15);
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(89, 115, 147, 0.2);
  }
}

.productWrapper {
  padding-right: 10px;
}

.title {
  display: flex;
  justify-content: space-between;

  .source {
    width: 44%;
  }

  .sourceLink {
    width: 54%;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.item {
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 12px;
  }
}

.source {
  display: flex;
  flex-direction: column;

  .sourceItem {
    margin-bottom: 8px;
  }

  .sourceButton {
    width: fit-content;
  }

  :global {
    .ant-btn {
      color: @primary-color;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  width: 44%;
  color: @primary-color;

  span {
    span {
      white-space: pre-wrap;
    }
  }

  .category {
    margin-right: 20px;
    cursor: default;
  }
}

.infoCategories {
  width: 54%;
}

.activeCategories {
  display: flex;
  flex-direction: column;

  .buttonCategory:not(:last-child) {
    margin-bottom: 12px;
  }
}

.parentCategory {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  p {
    position: relative;

    &:not(:first-of-type)::before {
      position: absolute;
      top: 0;
      right: -17px;
      content: '/';
      width: 10px;
      height: 20px;
    }
  }

  & > div {
    display: flex;
    flex-direction: row-reverse;
  }

  .button {
    margin-right: 20px;
  }
}

.highlight {
  padding: 0;
  color: @white-color;
  background-color: @high-color;
  border-radius: 3px;
}

.description {
  margin-bottom: 12px;
}

.hard {
  color: #e50000;
}

.light {
  color: #008000;
}

.expand {
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-size: 13px;
  background-color: #eff1f2;

  .expandTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
}

.button > span {
  color: @primary-color;
}

.addButton > span {
  color: @white-color;
}

.active {
  margin-bottom: 12px;
}

.center {
  display: flex;
  justify-content: center;
}

.activeLine {
  width: fit-content;
  padding: 0 3px;
  color: @white-color;
  background-color: @high-color;
  border-radius: 3px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;