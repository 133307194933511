.wrapper {
  display: flex;
  align-items: flex-start;
}
.avatar {
  min-width: 150px;
}
.body {
  margin-left: 24px;
}
.name {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1.2em;
  color: #597393;
}
.status {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.2em;
  color: @gray-color;
}
.data {
  margin-bottom: 16px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;