.body {
  :global {
    .ant-input,
    .ant-input::placeholder {
      color: @primary-color;
    }

    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }

    .ant-tag {
      padding: 4px 16px;
      border: 1px solid #c7d3dc;
      border-radius: 16px;
    }

    .ant-btn {
      color: @primary-color;
      border-radius: 7px;
    }

    .anticon-clear {
      color: @primary-color;
    }
  }
}

.searchSettings {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
}

.search {
  margin-bottom: 8px;
  max-width: 420px;
}

.center {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  align-items: center;
}

.clear {
  margin-left: 16px;

  svg {
    width: 20px;
    height: 20px;
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;