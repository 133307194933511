.list {
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  max-height: 600px;
  overflow-y: auto;
  scrollbar-color: rgba(89, 115, 147, 0.2);

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(89, 115, 147, 0.1);
    box-shadow: inset 0 0 6px rgba(89, 115, 147, 0.1);
    background-color: #f5f5f5;
    border: 1px solid rgba(89, 115, 147, 0.15);
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(89, 115, 147, 0.2);
  }
}

.status {
  font-weight: bold;
}

.info {
  margin-left: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #597393;

  p:first-child {
    margin-bottom: 9px;
  }
}

.date {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 14px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;