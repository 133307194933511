.wrapper {
  :global {
    .ant-card {
      border-radius: 7px;
    }

    .ant-table-cell-with-append {
      display: flex;
      align-items: center;
    }

    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }

    .ant-btn {
      border-radius: 7px;
    }
  }
}

.title {
  margin-bottom: 0;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.name {
  display: flex;
  align-items: center;
  color: @primary-color;
}

.text {
  margin-left: 10px;
}

.spinner {
  display: flex;
  justify-content: center;
}

.menu {
  :global {
    .ant-dropdown-menu-item {
      color: #597393;
    }

    .ant-dropdown-menu {
      border-radius: 7px;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;