.editNaming {
  :global {
    .ant-btn {
      border-radius: 7px;
    }

    .ant-form-item-label > label {
      color: @primary-color;
    }

    .ant-input {
      border-radius: 7px;
      color: @primary-color;
    }
  }
}

.title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: @primary-color;
}

.buttons {
  display: flex;

  .button {
    margin-right: 15px;
  }
}

.namings {
  :global {
    .ant-form-item-control-input {
      box-sizing: border-box;
      margin: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum', 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 11px;
      font-size: 14px;
      line-height: 1.5715;
      background-color: @white-color;
      background-image: none;
      border: 1px solid #d9d9d9;
      transition: all 0.3s;
      border-radius: 7px;
      color: #597393;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;