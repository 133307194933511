.chat {
  :global {
    .ant-row {
      margin-left: -8px;
      margin-right: -8px;
      margin-bottom: 24px;
    }

    .ant-col {
      > div {
        height: 100%;
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 8px;
      }
    }

    .ant-card-body {
      position: relative;
      height: 100%;
      padding: 0;
    }

    .ant-card {
      height: 100%;
      border-radius: 8px;
    }

    .ant-tabs,
    .ant-tabs-content,
    .ant-tabs-tabpane {
      height: 100%;
    }

    .ant-tabs-nav-list {
      display: flex;
      width: 100%;
      color: @primary-color;
    }

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }

    .ant-tabs-nav-list {
      display: flex;
      width: 100%;
      color: @primary-color;
    }

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;