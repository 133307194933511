.table {
  :global {
    thead > tr > th {
      color: @primary-color;
      font-weight: bold;
    }

    tbody > tr > td {
      color: @primary-color;
    }

    .ant-table-cell {
      button {
        border-radius: 8px;
      }
    }
  }

  .actions {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
  }
}

.searchSettings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;

  :global {
    .ant-btn {
      color: @primary-color;
      border-radius: 7px;
    }
  }

  .clear {
    svg {
      width: 20px;
      height: 20px;
      color: @primary-color;
    }
  }

  .balances {
    color: @primary-color;
    font-size: 20px;
    font-weight: 700;
    display: flex;
  }

  .options {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 16px !important;
    }
  }
}

.cell {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  white-space: nowrap;
  align-items: center;

  span {
    cursor: pointer;
  }
}

.copy {
  cursor: pointer;
  border-bottom: 1px dashed;
  line-height: 1;

  &:active {
    border-bottom: none;
  }
}

.link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.nowrap {
  white-space: nowrap;
}

.mobileCards {

}

.activeToggler {
  color: @primary-color;
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.mobileCardWrapper {
  margin-top: 16px;
  border-radius: 12px;
  background-color: #FAFAFA;
}

.mobileCardMainInfo {
  padding: 12px 16px;
  border-radius: 12px;
  background-color: white;
}

.mobileCardSubInfo {
  padding: 8px 16px;
  color: @primary-color;
  gap: 8px;
}

.mobileCardMainInfo > div {
  color: @primary-color
}

@media screen and (max-width: 441px) {
  .table {
    display: none;
  }
  .searchSettings {

    .options {
      display: none;
    }

    .balances {
      display: block;
    }

    padding-bottom: 0;
  }
  .mobileCards {
    display: block;
  }
}

@media screen and (min-width: 441px) {
  .mobileCards {
    display: none;
  }
}

.mobilePavilionSearch {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.mobilePavilionSearch > div {
  border-radius: 4px;
  flex-grow: 1;
}

.mobilePavilionSearch > div > div {
  border-radius: 4px;
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;