.AddProductFaq {
  padding: 24px 16px;
  border-radius: 8px;
  background-color: white;
  text-align: left;

  h3 {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #597393;
  }

  p {
    color: #597393;

    a {
      font-weight: 600;
    }
  }
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;