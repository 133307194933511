.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: black !important;

  &.fixed {
    position: fixed !important;
    right: 40px !important;
  }
  
  &:hover {
    ::before,
    ::after {
      opacity: 0.7;
    }
  }

  &.inside {
    color: @primary-color !important;
  }
}

.modal {
  :global {
    .ant-form-item-label > label {
      color: @primary-color;
    }
  }

  .head {
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 8px;

    .title {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.3px;
      color: @primary-color;
      margin-bottom: 0 !important;
    }

    .subTitle {
      font-size: 13px;
      font-weight: 400;
      color: @primary-color;
    }
  }
}
.modal p {
  color: @primary-color;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;