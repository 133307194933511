.card {
  width: 100%;

  :global {
    .ant-card-body {
      height: 100%;
    }

    .ant-btn {
      width: fit-content;
      padding: 5px 15px;
      background-color: #ededed;
      border-radius: 7px;
      margin-right: 10px;
      color: @primary-color;
    }

    .ant-tabs {
      margin-top: -12px;
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      flex-wrap: wrap;
      margin-left: 67px;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 4px;
    }

    .ant-tabs-nav::before {
      display: none;
    }

    .ant-tabs-tab {
      font-size: 20px;
      font-weight: bold;
      color: @gray-color;
    }

    .ant-tabs-tab-active {
      color: @primary-color;
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 24px;
  color: @primary-color;
}

.name {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
}

.data {
  margin-bottom: 12px;
  color: @primary-color;
  word-break: break-word;
}

.address {
  position: relative;

  .name {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.emptyAddress {
  margin-top: 44px;
}

@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;